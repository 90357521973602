.email-detail {
  .email-body {
    max-height: calc(100vh - 500px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .email-attachments {
    margin-top: 20px;
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .attachments {
      display: flex;
      flex-wrap: wrap;
      .attachment {
        display: flex;
        flex-direction: row;
        padding: 10px;
        width: 220px;
        margin: 0 16px 16px 0;
        border-radius: 5px;
        background-color: #e2f2fe;
        .file-icon {
          width: 50px;
          font-size: 24px;
          text-align: center;
          line-height: 50px;
        }
        .file-info {
          margin-left: 5px;
          width: 165px;
          .file-name {
            font-weight: bold;
          }
          .file-name, .file-size {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
