.invoice-form {
  .form-section {
    margin-bottom: 15px;
  }
  .form-actions {
    display: flex;
    justify-content: space-between;
    &.text-right {
      display: block;
      text-align: right;
    }
  }
  h3 {
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
  .extracted-files {
    padding-left: 15px;
    > a {
      display: block;
    }
  }
  .xml-file {
    font-weight: 500;
  }
}
