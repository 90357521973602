.invoice-preview {
  width: 100%;
  margin: auto;
  border: 1px solid #f0f0f0;
  padding: 1rem;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 6px 0 rgba(10, 1, 47, .08), 0 -4px 0 0 rgba(105, 46, 254, .004);

  .inv-name {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .inv-description {
    font-style: italic;
  }

  .inv-date, .inv-seller, .inv-buyer, .inv-payment {
    margin-bottom: 1rem;
  }

  .item-row span {
    font-weight: 500;
  }

  .inv-table-container {
    width: 100%;
    overflow: auto;
  }

  .inv-table {
    border-collapse: collapse;
    width: 100%;
  }

  .inv-table td, .inv-table th {
    padding: 0.25rem 0.5rem;
    border: 1px solid #333;
  }

  .inv-table th {
    text-align: center;
    font-weight: 500;
  }

  .inv-signatures {
    display: flex;
    padding: 2rem 0;
  }

  .inv-seller-signature, .inv-buyer-signature {
    flex: 1;
    padding-bottom: 2rem;
  }

  .inv-signatures .--title {
    text-align: center;
  }
}
