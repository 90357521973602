.list-files {
  max-width: 450px;
  .file-item {
    padding: 0 10px;
    display: flex;
    &:hover {
      background: #f0f0f0;
    }
    .file-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
    .actions {
      .icon {
        cursor: pointer;
        &:hover {
          color: #1890ff;
        }
      }
      .icon + .icon {
        margin-left: 5px;
      }
    }
  }
}
