.main-layout {
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 1000;

    .ant-layout-sider-children {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .upgrade-menu {
        color: #ffffff;
        padding: 1rem 1rem 1.5rem;

        .btn {
          width: 100%;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 8px;
          padding-right: 8px;
        }

        .btn + .btn {
          margin-top: 5px;
        }
      }
    }
  }

  .sider-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.15);
  }

  .main-content {
    background: #fff;
    padding: 24px;
    margin-bottom: 15px;
  }

  .main-breadcrumb {
    margin: 16px 0;
  }

  .main-layout-container {
    min-height: 100vh;
    margin-left: 200px;
    transition: margin-left ease-out 0.3s;
    @media all and (max-width: 991px) {
      transition: none;
      margin-left: 0 !important;
    }

    &.sider-collapsed {
      margin-left: 80px;
    }
  }

  .main-footer {
    padding-left: 18px;
    padding-right: 18px;
  }

  .select-tax-code {
    max-width: 450px;
    margin-right: 10px;

    .ant-select-selection-item {
      font-weight: 500;
    }
  }

  .main-header {
    display: flex;

    .header-notification {
      flex: 1;
    }

    .header-components {
      padding: 0 24px;
      white-space: nowrap;
      @media (max-width: 991px) {
        max-width: calc(100% - 70px);
      }

      a {
        color: rgba(0, 0, 0, 0.65);
      }

      .search-box {
        text-align: right;
        display: inline-block;
      }

      .header-icon {
        display: inline-block;
        height: 100%;
        cursor: pointer;
      }

      .notifications {
        &:hover, &.ant-dropdown-open {
          background: rgba(0, 0, 0, .025);
        }

        .ant-badge-count {
          top: -3px;
          right: -3px;
        }
      }
    }
  }
}
