.check-invoice {
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  padding: 10px;
  .check-invoice-item {
    .check-item-label {
      font-weight: 500;
      display: block;
      margin-bottom: 5px;
    }
    .result {
      .anticon {
        margin-right: 5px;
      }
    }
    .captcha-input {
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
      img, input {
        display: inline-block;
        width: auto;
        max-width: 300px;
      }
    }
  }
  .check-invoice-item + .check-invoice-item {
    margin-top: 10px;
  }
  .explain {
    padding-left: 22px;
    font-style: italic;
  }
}
