.skip-upload {
  margin-top: 16px;
}

.duplicated-invoices-popup {
  color: #333;
  .duplicated-invoices {
    max-height: calc(100vh - 400px);
    overflow: auto;
    ul {
      padding: 0;
      margin-bottom: 1rem;
    }
    li {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.select-number-format-popup {
  ul {
    max-height: 300px;
    overflow-y: auto;
  }
}
