.welcome-modal {
  width: 760px !important;
  @media (max-width: 767px) {
    width: calc(100vw - 16px) !important;
  }
  .ant-modal-body {
    width: 100%;
    height: 450px;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    @media (max-width: 767px) {
      height: auto;
    }
  }
  .step {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    figure {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      background: no-repeat center;
      background-size: contain;
      position: relative;
      &.fit {
        background-size: cover;
      }
      &.round {
        border-radius: 50%;
      }
      &.pos-left {
        background-position-x: left;
      }
      &.size-60 {
        background-size: 60%;
      }
    }
    &.two-col {
      .col {
        display: flex;
        height: 100%;
        width: 50%;
        flex: 1;
        max-width: 50%;
        padding: 1rem;
      }
      .col-img {
        align-items: center;
      }
      .col-main {
        flex-direction: column;
      }
      @media (max-width: 767px) {
        display: block;
        .col {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
        .col-img {
          height: 150px;
        }
      }
    }
    .--title {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
      padding-top: 0.5rem;
    }
    .--content {
      font-size: 1rem;
      flex: 1;
      .ant-form-item-label > label {
        font-size: 1rem;
      }
    }
    .--footer {
      padding-bottom: 0.5rem;
      display: flex;
      &.two-button {
        margin: 0 -4px;
        .ant-btn {
          flex: 1;
          margin: 0 4px;
        }
      }
    }
    &.welcome, &.finish {
      .col-img {
        background: #00BFA6;
      }
    }
    &.introduction {
      .col-main {
        color: #fff;
        background: #00BFA6;
      }
      .checks {
        p {
          display: inline-block;
          + p {
            margin-left: 1rem;
          }
        }
      }
    }
    &.storage {
      .col-img {
        background: #A29BFE;
      }
    }
    &.create-invoice {
      .col-img {
        background: #d80051;
      }
    }
    &.view-invoice {
      .col-main {
        color: #fff;
        background: #536DFE;
      }
    }
    &.tax-code {
      .col-img {
        color: #fff;
        background: #F9A826;
      }
    }
  }
}
