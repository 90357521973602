.auth-layout {
  height: 100vh;
  overflow: auto;
  position: relative;
  padding-bottom: 30px;
  min-height: 500px;
  .auth-container {
    max-width: 1300px;
    height: 100%;
    padding: 0 15px;
    margin: auto;
    display: flex;
    align-items: center;
  }
  .auth-slider {
    flex: 1;
    max-width: calc(100% - 400px);
    padding: 50px 30px 0;
    .image-wrapper {
      text-align: center;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      display: inline-block;
      background: no-repeat center center;
      background-size: contain;
      @media (max-width: 991px) {
        max-width: 300px;
      }
      @media (max-width: 768px) {
        max-width: 200px;
      }
    }
    .caption {
      color: #fff;
      display: block;
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      padding: 10px;
      font-style: italic;
    }
  }
  .auth-form {
    width: 400px;
    padding: 0 30px 50px;
    .login-form {
      margin: auto;
    }
  }
  .copy-right {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    a {
      color: rgba(255, 255, 255, 0.85);
    }
  }
}

@media (max-width: 991px) {
  .auth-layout {
    height: auto;
    .auth-container {
      display: block;
      .auth-slider, .auth-form {
        padding-left: 15px;
        padding-right: 15px;
      }
      .auth-slider {
        max-width: none;
      }
      .auth-form {
        width: auto;
        padding-top: 20px;
      }
    }
    .copy-right {
      position: static;
    }
  }
}
