.invoices {
  .search-form {
    margin-bottom: 20px;
    .ant-form-item {
      margin-bottom: 10px;
      &.hidden-label {
        .ant-form-item-label {
          opacity: 0;
          @media all and (max-width: 575px) {
            display: none;
          }
        }
      }
    }
  }
}
