@import '~antd/dist/antd.css';
@import "./effect";

html, body {
  font-family: 'Roboto', sans-serif;
}

@media all and (min-width: 992px) {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c0c0c0;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a0a0a0;
  }

  .mobile-only {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .pc-only {
    display: none;
  }
}

.d-none {
  display: none;
}

.ant-form-item-control:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

.common-form {
  .ant-form-item {
    margin-bottom: 10px;

    .ant-input-number {
      width: 100%;

      input {
        text-align: right;
      }
    }

    &.hidden-label {
      label {
        opacity: 0;
      }

      .ant-form-item-control {
        margin-top: -3px;
      }
    }

    &.hidden-label-sm {
      @media all and (max-width: 767px) {
        label {
          display: none;
        }
      }
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .has-warning + .ant-form-item {
    .ant-form-item-control {
      border: 1px solid #ffb500;
    }
  }

  .form-actions {
    margin-top: 8px;
  }

  .ant-form-item + .form-actions {
    margin-top: 16px;
  }

  .vertical {
    .ant-radio-wrapper {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}

.common-form, .btn-group {
  button + button, .ant-btn + .ant-btn {
    margin-left: 10px;
  }
}

.login-form {
  max-width: 350px;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  margin: 10px auto;

  .login-form-button {
    width: 100%;
  }

  .other-option {
    margin-top: 10px;
  }
}

.common-table {
  .ant-table-content {
    overflow: auto hidden !important;
  }
}

.table-footer {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.table-header {
  margin-bottom: 15px;
}

.action-buttons {
  .ant-btn + .ant-btn {
    margin-left: 10px;
  }
}

@keyframes blink {
  0% {
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
  }
  50% {
    color: #40a9ff;
    border-color: #40a9ff;
  }
  100% {
    color: rgba(0, 0, 0, 0.65);
    border-color: #d9d9d9;
  }
}

.common-drawer {
  .drawer-actions {
    display: flex;
    justify-content: space-between;
  }
}

.tos {
  max-height: calc(100vh - 350px);
  overflow: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #a0a0a0;
}

.text-red {
  color: #ff4d4f;
}

.text-orange {
  color: #ffb700;
}

.text-green {
  color: #0eab00;
}

.text-blue {
  color: #1890ff;
}

.text-bold {
  font-weight: bold;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.pl-35 {
  padding-left: 35px !important;
}

.fw-500 {
  font-weight: 500;
}

.clickable {
  cursor: pointer;
}

.btn {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  display: inline-block;
  &.btn-success {
    background-color: #0eab00;
    color: #fff;
    &:hover {
      background-color: #0f9200;
    }
  }
  &.btn-warning {
    background-color: #e3af05;
    color: #fff;
    &:hover {
      background-color: #c69302;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.common-table {
  .actions {
    a {
      color: #333;
    }

    a + a {
      margin-left: 10px;
    }
  }
}

.ant-list {
  color: rgba(0, 0, 0, 0.85);
}

.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.85);
}

.font-500 {
  font-weight: 500;
}

.ant-table-row {
  &.is-blocked {
    color: #ff4d4f;
  }

  &.is-deleted {
    opacity: 0.5;
    text-decoration: line-through;
  }
}
