.xml-view-modal {
  .ant-modal-body {
    max-height: calc(100vh - 300px);
    overflow: auto;
    background: #333333;

    .xml-node {
      .node-name {
        color: #ffb500;
      }

      .node-value {
        color: #ffffff;
      }

      &.is-selected {
        > .node-name {
          color: #0eab00;
        }
      }
    }
  }
}
