.pricing {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto 3em;

  &-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    text-align: center;
    -webkit-flex: 0 1 330px;
    flex: 0 1 330px;
  }

  &-action {
    color: inherit;
    border: none;
    background: none;

    &:focus {
      outline: none;
    }
  }
}


.pricing-feature-list {
  text-align: left;
}


.pricing-palden .pricing-item {
  cursor: default;
  background: #fff;
  box-shadow: 0 0 10px rgba(46, 59, 125, 0.23);
  border-radius: 20px 20px 10px 10px;
  margin: 1em;
}

@media screen and (min-width: 992px) {
  .pricing-palden .pricing-item {
    margin: 1em -0.5em;
  }
  .pricing-palden .pricing__item--featured {
    margin: 0;
    z-index: 10;
    box-shadow: 0 0 20px rgba(46, 59, 125, 0.23);
  }
}

.pricing-palden {
  .pricing-deco {
    border-radius: 10px 10px 0 0;
    background: #1890ff;
    padding: 3em 0 5em;
    position: relative;
  }

  .pricing-deco-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
  }

  .pricing-title {
    font-size: 0.875rem;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #fff;
  }

  .deco-layer {
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
  }

  .pricing-item {
    &:hover {
      .deco-layer--1 {
        -webkit-transform: translate3d(15px, 0, 0);
        transform: translate3d(15px, 0, 0);
      }

      .deco-layer--2 {
        -webkit-transform: translate3d(-15px, 0, 0);
        transform: translate3d(-15px, 0, 0);
      }
    }
  }

  .icon {
    font-size: 2.5em;
  }

  .pricing-price {
    font-size: 3.5em;
    font-weight: bold;
    padding: 0;
    color: #fff;
    margin: 0 0 0.25em 0;
    line-height: 0.75;
  }

  .pricing-currency {
    font-size: 0.75rem;
    vertical-align: top;
  }

  .pricing-period {
    font-size: 0.75rem;
    padding-right: 10px;
    font-style: italic;
  }

  .pricing__sentence {
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 0.5em;
  }

  .pricing-feature-list {
    margin: 0;
    padding: 0.25em 0 2.5em;
    list-style: none;
    text-align: center;
  }

  .pricing-feature {
    padding: 1em 0;

    &.not-available {
      text-decoration: line-through;
      opacity: 0.5;
    }
  }

  .pricing-action {
    font-weight: bold;
    margin: auto 3em 2em 3em;
    padding: 1em 2em;
    color: #fff;
    border-radius: 30px;
    background: #1890ff;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;

    &:hover, &:focus {
      background-color: #100A13;
    }
  }

}

.pricing-palden .pricing-item--featured .pricing-deco {
  padding: 4em 0 5em 0;
}
