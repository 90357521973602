.invoice-detail-footer {
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
}

.invoice-detail {
  th.part-header {
    font-weight: bold;
  }
  td.content-right {
    text-align: right;
  }
}
