.xml-node {
  font-size: 0;

  .node-name + .node-child {
    display: block;
    padding-left: 30px;
  }

  .node-name, .node-value {
    display: inline;
    font-size: 14px;
  }

  .node-name {
    font-weight: 500;
  }
}
