.select-tax-code-item {
  .com-name {
    font-weight: 500;
    max-width: 235px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .com-tax-code {
    font-weight: normal;
  }
}

.select-tax-code {
  max-width: 250px;

  @media (max-width: 991px) {
    .ant-select-selection-item {
      .com-name {
        display: none;
      }
    }
  }
}
